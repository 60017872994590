.main-view {
  background-color: #ffffff;
  min-height: calc(100vh - 180px);
  border-radius: 8px;
}

.switch-input-view {
  margin-right: 10px;
  min-width: 150px;
  min-height: 80px;
}

.switch-input-view .toggle-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.btn-main-View {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.btn-main-View .save-btn {
  background-color: #000000;
  cursor: pointer;
  text-transform: capitalize;
  font-family: GilroySemiBold;
  font-size: 15px;
  border-radius: 9px;
  height: 44px;
  width: 100%;
  color: #ffffff !important;
}

.btn-main-View .cancel-btn {
  border: 1px solid #000000;
  cursor: pointer;
  text-transform: capitalize;
  font-family: GilroySemiBold;
  font-size: 15px;
  color: #000000;
  border-radius: 9px;
  height: 44px;
  width: 100%;
  margin-right: 10px;
}
