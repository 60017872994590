.main-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #aaaaaa;
  padding: 2px;
  width: fit-content;
}

.main-view .add-sub-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #000000;
  cursor: pointer;
}

.main-view .add-sub-text {
  font-family: GilroyBold;
  font-size: 18px;
  color: #ffffff;
}

.main-view .count-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
}

.main-view .count-text {
  font-family: GilroySemiBold;
  font-size: 14px;
  color: #000000;
}
